import React, { useContext, useEffect, useState } from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import ChatMessageLayer from "../components/ChatMessageLayer";
import { GlobalInfo } from "../App";
import axios from "axios";
import useAuth from "../hook/useAuth";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Icon } from "@iconify/react/dist/iconify.js";
import DocumentListShow from "./DocumentListShow";
import TranslateDocument from "./TranslateDocument";
import { Button } from "react-bootstrap";
import PricingTranslate from "./PricingTranslate";

const ChatMessagePage = () => {
  const [showFiles, setShowFiles] = useState(false);
  const context = useContext(GlobalInfo);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [data, setData] = useState();

  const { student_id, document_id } = useParams();

  const getDocuments = async () => {
    try {
      const response = await axios.post(
        `${context.apiEndPoint}get-details`,
        {
          student_id: student_id,
          translation_doc_id: document_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data); // Store the response data
    } catch (err) {
      if (err.response) {
        console.error("Error response:", err.response.data);
      } else {
        console.error("Error:", err);
      }
    }
  };

  useEffect(() => {
    getDocuments();
  }, [student_id, document_id]);

  return (
    <>
      <MasterLayout>
        <Breadcrumb
          documentTitle={`${data && data?.document_name}`}
          studentTitle={`${data && data?.first_name} ${data?.last_name}`}
          student_id={student_id}
          document_id={document_id}
        />
        <PricingTranslate />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "15px",
            flexWrap: "wrap",
          }}
        >
          <TranslateDocument />
          <ChatMessageLayer showFiles={showFiles} setShowFiles={setShowFiles} />
        </div>
      </MasterLayout>
    </>
  );
};

export default ChatMessagePage;
