import React from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
const Breadcrumb = ({
  title,
  documentTitle,
  studentTitle,
  student_id,
  document_id,
}) => {
  return (
    <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mb-24">
      <h6 className="fw-semibold mb-0">Dashboard</h6>
      <ul className="d-flex align-items-center gap-2">
        <li className="fw-medium">
          <Link
            to="/documents"
            className="d-flex align-items-center gap-1 hover-text-primary"
          >
            <Icon
              icon="solar:home-smile-angle-outline"
              className="icon text-lg"
            />
            Dashboard
          </Link>
        </li>
        <li> - </li>

        {studentTitle && (
          <li className="fw-medium">
            <Link
              to={`/documents/translator/student/${student_id}`}
              className="d-flex align-items-center gap-1 hover-text-primary"
            >
              {studentTitle}
            </Link>
          </li>
        )}
        {documentTitle && student_id && <li> - </li>}
        {documentTitle && (
          <li className="fw-medium">
            {documentTitle}
            {/* <Link
              to="/documents"
              className="d-flex align-items-center gap-1 hover-text-primary"
            >
              {documentTitle}
            </Link> */}
          </li>
        )}
        {title && <li className="fw-medium">{title}</li>}
      </ul>
    </div>
  );
};

export default Breadcrumb;
