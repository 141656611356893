import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import StudentDocumentComponent from "../components/StudentDocumentComponent";
import StudentsDocuments from "../components/child/StudentDocuments";
import { useParams } from "react-router-dom";
import "../indexcss.js";
import Breadcrumb from "../components/Breadcrumb.jsx";

const StudentDocumentPage = () => {
  const { student_id } = useParams();
  return (
    <>
      <MasterLayout>
        <Breadcrumb title="Student Documents" />
        <StudentsDocuments student_id={student_id} />
      </MasterLayout>
    </>
  );
};

export default StudentDocumentPage;
