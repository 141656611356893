import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GlobalInfo } from "../App";
import useAuth from "../hook/useAuth";
import axios from "axios";
import { toast, Toaster } from "sonner";

const SignUpLayer = () => {
  const navigate = useNavigate();
  const tokenData = useAuth();

  const context = useContext(GlobalInfo);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [cnfPassword, setCnfPassword] = useState("");
  const [userType, setUserType] = useState("admin");

  const [firstNameError, setfirstNameError] = useState("");
  const [emailError, setemailError] = useState("");
  const [mobileError, setmobileError] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [cnfpasswordError, setcnfpasswordError] = useState("");

  function validateEmail(email) {
    // Regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const handleSubmit = async () => {
    setfirstNameError("");
    setemailError("");
    setmobileError("");
    setpasswordError("");
    setcnfpasswordError("");

    if (firstName.length <= 0) {
      setfirstNameError("First Name is required");
    }
    if (email.length <= 0) {
      setemailError("Email is required");
    }
    if (mobile.length <= 0) {
      setmobileError("Mobile is required");
    }
    if (password.length <= 0) {
      setpasswordError("Password is required");
    }
    if (cnfPassword.length <= 0) {
      setcnfpasswordError("Confirm Password is required");
    }
    if (password.trim() !== cnfPassword.trim()) {
      setcnfpasswordError("Confirm password should match password");
    }
    if (password.trim().length < 6) {
      setpasswordError("Password should be atleast 6 characters ");
    }

    if (!validateEmail(email)) {
      setemailError("Email should be valid");
    }

    if (
      firstName.length <= 0 ||
      email.length <= 0 ||
      mobile.length <= 0 ||
      password.length <= 0 ||
      cnfPassword.length <= 0 ||
      password.trim() !== cnfPassword.trim() ||
      password.trim().length < 6 ||
      !validateEmail(email)
    ) {
      return;
    }

    const obj = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      mobile: mobile,
      password: password,
      usertype: userType,
    };

    // console.log(obj);

    try {
      const response = await axios({
        method: "post",
        url: context.apiEndPoint + `signup`,
        data: obj,
      });

      //   console.log(response.data);
      if (response.data.message) {
        toast.success("Account has been created successfully", {
          description: "You can login now",
        });
        setTimeout(() => {
          navigate("/login");
        }, 6000);
      }
    } catch (error) {
      console.log(error);

      toast.error(
        `Something went wrong Contact Us <a href='mailto:support@physioplusnetwork.com'>support@physioplusnetwork.com</a>`
      );
    }
  };

  return (
    <section className="auth bg-base d-flex flex-wrap">
      <Toaster richColors position="top-right" />
      {/* <div className="auth-left d-lg-block d-none">
        <div className="d-flex align-items-center flex-column h-100 justify-content-center">
          <img src="assets/images/auth/auth-img.png" alt="" />
        </div>
      </div> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div className="max-w-464-px mx-auto w-100">
          <div>
            {/* <Link to="/" className="mb-40 max-w-290-px">
              <img src="assets/images/logo.png" alt="" />
            </Link> */}
            <h4 className="mb-12">Sign Up to your Account</h4>
            <p className="mb-32 text-secondary-light text-lg">
              Welcome back! please enter your detail
            </p>
          </div>
          <form action="#">
            <div className="icon-field mb-16">
              <span className="icon top-50 translate-middle-y">
                <Icon icon="f7:person" />
              </span>
              <input
                type="text"
                id="firstname"
                placeholder="First name"
                className="form-control h-56-px bg-neutral-50 radius-12"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setfirstNameError("");
                }}
              />
            </div>
            {firstNameError != "" && (
              <p className="text-red-600">{firstNameError}</p>
            )}
            <div className="icon-field mb-16">
              <span className="icon top-50 translate-middle-y">
                <Icon icon="f7:person" />
              </span>
              <input
                id="lastname"
                className="form-control h-56-px bg-neutral-50 radius-12"
                placeholder="Last name"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                type="text"
              />
            </div>

            <div className="icon-field mb-16">
              <span className="icon top-50 translate-middle-y">
                <Icon icon="mage:email" />
              </span>
              <input
                id="email"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setemailError("");
                }}
                type="email"
                className="form-control h-56-px bg-neutral-50 radius-12"
              />
            </div>
            {emailError != "" && <p className="text-red-600">{emailError}</p>}
            <div className="icon-field mb-16">
              <span className="icon top-50 translate-middle-y">
                <Icon icon="mage:email" />
              </span>
              <input
                id="mobile_no"
                placeholder="Mobile number"
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                  setmobileError("");
                }}
                type="text"
                className="form-control h-56-px bg-neutral-50 radius-12"
              />
            </div>
            {mobileError != "" && <p className="text-red-600">{mobileError}</p>}
            <div className="mb-20">
              <div className="position-relative ">
                <div className="icon-field">
                  <span className="icon top-50 translate-middle-y">
                    <Icon icon="solar:lock-password-outline" />
                  </span>
                  <input
                    id="password"
                    placeholder="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setpasswordError("");
                    }}
                    type="password"
                    className="form-control h-56-px bg-neutral-50 radius-12"
                  />
                </div>
                <span
                  className="toggle-password ri-eye-line cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light"
                  data-toggle="#your-password"
                />
              </div>
            </div>
            {passwordError != "" && (
              <p className="text-red-600">{passwordError}</p>
            )}
            <div className="mb-20">
              <div className="position-relative ">
                <div className="icon-field">
                  <span className="icon top-50 translate-middle-y">
                    <Icon icon="solar:lock-password-outline" />
                  </span>
                  <input
                    id="confirm_password"
                    placeholder="confirm password"
                    value={cnfPassword}
                    onChange={(e) => {
                      setCnfPassword(e.target.value);
                      setcnfpasswordError("");
                    }}
                    type="password"
                    className="form-control h-56-px bg-neutral-50 radius-12"
                  />
                </div>
                <span
                  className="toggle-password ri-eye-line cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light"
                  data-toggle="#your-password"
                />
              </div>

              <span className="mt-12 text-sm text-secondary-light">
                Your password must have at least 8 characters
              </span>
            </div>
            {cnfpasswordError != "" && (
              <p className="text-red-600">{cnfpasswordError}</p>
            )}
            <div className="">
              {/* <div className="d-flex justify-content-between gap-2">
                <div className="form-check style-check d-flex align-items-start">
                  <input
                    className="form-check-input border border-neutral-300 mt-4"
                    type="checkbox"
                    defaultValue=""
                    id="condition"
                  />
                  <label
                    className="form-check-label text-sm"
                    htmlFor="condition"
                  >
                    By creating an account means you agree to the
                    <Link to="#" className="text-primary-600 fw-semibold">
                      Terms &amp; Conditions
                    </Link>{" "}
                    and our
                    <Link to="#" className="text-primary-600 fw-semibold">
                      Privacy Policy
                    </Link>
                  </label>
                </div>
              </div> */}
            </div>
            <button
              //   type="submit"
              onClick={() => {
                handleSubmit();
              }}
              //   type="submit"
              className="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32"
            >
              {" "}
              Sign Up
            </button>
            {/* <div className="mt-32 center-border-horizontal text-center">
              <span className="bg-base z-1 px-4">Or sign up with</span>
            </div> */}
            {/* <div className="mt-32 d-flex align-items-center gap-3">
              <button
                type="button"
                className="fw-semibold text-primary-light py-16 px-24 w-50 border radius-12 text-md d-flex align-items-center justify-content-center gap-12 line-height-1 bg-hover-primary-50"
              >
                <Icon
                  icon="ic:baseline-facebook"
                  className="text-primary-600 text-xl line-height-1"
                />
                Google
              </button>
              <button
                type="button"
                className="fw-semibold text-primary-light py-16 px-24 w-50 border radius-12 text-md d-flex align-items-center justify-content-center gap-12 line-height-1 bg-hover-primary-50"
              >
                <Icon
                  icon="logos:google-icon"
                  className="text-primary-600 text-xl line-height-1"
                />
                Google
              </button>
            </div> */}
            <div className="mt-32 text-center text-sm">
              <p className="mb-0">
                Already have an account?{" "}
                <Link to="/login" className="text-primary-600 fw-semibold">
                  Sign In
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SignUpLayer;
