import React, { useState, useContext, useEffect } from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import $ from "jquery";
import axios from "axios";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GlobalInfo } from "../../App";
import "../../indexcss";
const StudentsDocuments = ({ setStudentData, student_id }) => {
  const context = useContext(GlobalInfo);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [loading, setLoading] = useState(true);

  const [data, setdata] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});

  const getDocuments = async () => {
    try {
      const response = await axios.get(
        `${context.apiEndPoint}admin/translator/documents/` + student_id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setdata(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDocuments();
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      const tableInstance = $("#dataTable").DataTable({
        pageLength: 10,
      });

      return () => {
        tableInstance.destroy(true);
      };
    }
  }, [data]);

  return (
    <div className="card basic-data-table">
      <div className="card-header flex justify-between items-center flex-wrap">
        <div>
          <h5 className="card-title mb-0">Documents</h5>
          <p>List of all documents requested by clients </p>
        </div>
        {data.length > 0 && (
          <div className="flex gap-2 justify-center items-center">
            <p> Name : </p>
            <div className="text-success-500 font-bold capitalize">
              {data[0].first_name} {data[0].last_name}
            </div>
          </div>
        )}
      </div>
      <div className="card-body overflow-x-scroll no-scrollbar">
        {data && data.length > 0 && (
          <table
            className="table bordered-table mb-0"
            id="dataTable"
            data-page-length={10}
          >
            <thead>
              <tr scope="col">
                <th>Document Name</th>
                <th>Paid Amount</th>
                <th>Net Amount</th>
                <th>Payment Status</th>
                <th>Document Status</th>
                <th>Payment Approved Status</th>
                <th>Last Updated At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {!loading && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Link
                        className="text-warning-600 font-bold"
                        to={
                          "/documents/candidate/translations/" +
                          item.student_id +
                          "/documents/" +
                          item.doc_cat_id +
                          "/" +
                          item.translation_doc_id
                        }
                      >
                        {item.document_name}
                      </Link>
                    </td>

                    <td>
                      <div className="text-center">{item.paid_amount}</div>
                    </td>
                    <td>
                      <div className="text-center">{item.net_amount}</div>
                    </td>
                    <td>
                      <div>
                        {item.net_amount - item.paid_amount == 0 ? (
                          <span className="bg-success-focus text-success-main px-24 py-4 rounded-pill fw-medium text-sm">
                            Paid
                          </span>
                        ) : (
                          <span className="bg-warning-focus text-warning-main px-24 py-4 rounded-pill fw-medium text-sm">
                            Pending
                          </span>
                        )}
                      </div>
                    </td>
                    <td>
                      <div>
                        {item.translation_status == 0 ? (
                          <span className="bg-warning-focus text-warning-main px-24 py-4 rounded-pill fw-medium text-sm">
                            Pending
                          </span>
                        ) : (
                          <span className="bg-success-focus text-success-main px-24 py-4 rounded-pill fw-medium text-sm">
                            Completed
                          </span>
                        )}
                      </div>
                    </td>
                    <td>
                      <div>
                        {item.is_payment_approved == 1 ? (
                          <span className="bg-success-focus text-success-main px-24 py-4 rounded-pill fw-medium text-sm">
                            Approved
                          </span>
                        ) : (
                          <span className="bg-warning-focus text-warning-main px-24 py-4 rounded-pill fw-medium text-sm">
                            Pending
                          </span>
                        )}
                      </div>
                    </td>
                    <td>
                      <div>
                        {moment(item.modified_at, "YYYY-MM-DD HH:mm:ss").format(
                          "DD-MM-YYYY, HH:mm"
                        )}
                      </div>
                    </td>

                    <td>
                      <Link
                        to={
                          "/documents/candidate/translations/" +
                          item.student_id +
                          "/documents/" +
                          item.doc_cat_id +
                          "/" +
                          item.translation_doc_id
                        }
                        className="bg-primary-light text-primary-600 px-24 py-4 rounded-pill fw-medium text-sm"
                        style={{
                          backgroundColor: "var(--brand)",
                          borderRadius: "4px",
                          textDecoration: "none",
                        }}
                      >
                        <span>View Document</span>
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10" className="text-center">
                    {loading ? "Loading data..." : "No data available"}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default StudentsDocuments;
