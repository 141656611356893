// import React, { useState, useContext, useEffect } from "react";
// import {
//   ColumnDef,
//   ColumnFiltersState,
//   SortingState,
//   VisibilityState,
//   flexRender,
//   getCoreRowModel,
//   getFilteredRowModel,
//   getPaginationRowModel,
//   getSortedRowModel,
//   useReactTable,
// } from "@tanstack/react-table";
// import { Icon } from "@iconify/react/dist/iconify.js";
// import axios from "axios";
// import moment from "moment";
// import { Link, useNavigate } from "react-router-dom";
// import { GlobalInfo } from "../../App";
// import "datatables.net-dt/js/dataTables.dataTables.js";
// import $ from "jquery";

// const TranslatorDocuments = ({ renderDocuments }) => {
// const [token, setToken] = useState(localStorage.getItem("token"));
// const context = useContext(GlobalInfo);
// const [sorting, setSorting] = useState([]);
// const [columnFilters, setColumnFilters] = useState([]);
// const [columnVisibility, setColumnVisibility] = useState({});
// const [currentType, setCurrentType] = useState("documents");
// const [rowSelection, setRowSelection] = useState({});
// const navigate = useNavigate();
// const [data, setData] = useState([]);
// const [loading, setLoading] = useState(true);

//   const columns = [
//     {
//       accessorKey: "fullname",
//       header: "Full Name",
//       cell: ({ row }) => (
// <Link to={`/documents/translator/student/${row.original.student_id}`}>
//   {row.original.first_name} {row.original.last_name}
// </Link>
//       ),
//     },
//     {
//       accessorKey: "document_name",
//       header: "Document Name",
//       cell: ({ row }) => (
//         <Link
//           to={
//             "/documents/candidate/translations/" +
//             row.original.student_id +
//             "/documents/" +
//             row.original.doc_cat_id +
//             "/" +
//             row.original.translation_doc_id
//           }
//         >
//           {row.original.document_name}
//         </Link>
//       ),
//     },
//     {
//       accessorKey: "number_of_pages",
//       header: "Number of pages",
//       cell: ({ row }) => (
//         <div className="text-center">{row.original.num_pages}</div>
//       ),
//     },

//     {
//       accessorKey: "net_amount",
//       header: "Net Amount",
//       cell: ({ row }) => (
//         <div className="text-center">{row.original.net_amount}</div>
//       ),
//     },
//     {
//       accessorKey: "paid_amount",
//       header: "Paid Amount",
//       cell: ({ row }) => (
//         <div className="text-center">{row.original.paid_amount}</div>
//       ),
//     },
//     {
//       accessorKey: "payment_approved_status",
//       header: "Payment Approved Status",
//       cell: ({ row }) => (
//         <div className="text-center">
//           {row.original.is_payment_approved === 1 ? (
//             <span className="bg-success-focus text-success-main px-24 py-4 rounded-pill fw-medium text-sm">
//               Approved
//             </span>
//           ) : (
//             <span className="bg-danger-focus text-danger-main px-24 py-4 rounded-pill fw-medium text-sm">
//               Pending
//             </span>
//           )}
//         </div>
//       ),
//     },

//     {
//       accessorKey: "payment_received_status",
//       header: "Payment Received Status",
//       cell: ({ row }) => (
//         <div className="text-center">
//           {row.original.payment_status === 1 ? (
//             <span className="bg-success-focus text-success-main px-24 py-4 rounded-pill fw-medium text-sm">
//               Paid
//             </span>
//           ) : (
//             <span className="bg-danger-focus text-danger-main px-24 py-4 rounded-pill fw-medium text-sm">
//               Pending
//             </span>
//           )}
//         </div>
//       ),
//     },
//     {
//       accessorKey: "document_translation_status",
//       header: "Document Translation Status",
//       cell: ({ row }) => (
//         <div className="text-center">
//           {row.original.translation_status === 0 ? (
//             <span className="bg-warning-focus text-warning-main px-24 py-4 rounded-pill fw-medium text-sm">
//               Pending
//             </span>
//           ) : (
//             <span className="bg-success-focus text-success-main px-24 py-4 rounded-pill fw-medium text-sm">
//               Completed
//             </span>
//           )}
//         </div>
//       ),
//     },

//     {
//       accessorKey: "last_updated_at",
//       header: "Last Updated At",
//       cell: ({ row }) => (
//         <div className="text-center">
//           {moment(row.original.latest_modified_at * 1000).format(
//             "DD-MM-YYYY, HH:mm"
//           )}
//         </div>
//       ),
//     },
//     {
//       id: "actions",
//       header: "Actions",
//       cell: ({ row }) => (
//         <div
//           className="cursor-pointer bg-blue-500 p-2 rounded-md"
//           onClick={() =>
//             navigate(
//               `/documents/candidate/translations/${row.original.student_id}/documents/${row.original.doc_cat_id}/${row.original.translation_doc_id}`
//             )
//           }
//         >
//           Open
//         </div>
//       ),
//     },
//   ];

//   const table = useReactTable({
//     data,
//     columns,
//     onSortingChange: setSorting,
//     onColumnFiltersChange: setColumnFilters,
//     getCoreRowModel: getCoreRowModel(),
//     getPaginationRowModel: getPaginationRowModel(),
//     getSortedRowModel: getSortedRowModel(),
//     getFilteredRowModel: getFilteredRowModel(),
//     onColumnVisibilityChange: setColumnVisibility,
//     onRowSelectionChange: setRowSelection,
//     state: {
//       sorting,
//       columnFilters,
//       columnVisibility,
//       rowSelection,
//     },
//   });

//   useEffect(() => {
//     if (data.length > 0) {
//       const tableInstance = $("#dataTable").DataTable({
//         pageLength: 10,
//       });
//       return () => {
//         tableInstance.destroy(true);
//       };
//     }
//   }, [data]);

// const getStudents = async () => {
//   try {
//     const response = await axios.get(
//       `${context.apiEndPoint}admin/translator/documents`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     setData(response.data);
//   } catch (error) {
//     console.error(error);
//   } finally {
//     setLoading(false);
//   }
// };

// useEffect(() => {
//   getStudents();
//   console.log("Data fetched:", data);
// }, [token]);
//   return (
//     <div className="card basic-data-table">
//       <div className="card-header">
//         <h5 className="card-title mb-0">Documents</h5>
//         <p>List of all documents requested by clients</p>
//       </div>
//       <div className="card-body overflow-x-scroll no-scrollbar">
//         <table
//           className="table bordered-table mb-0"
//           id="dataTable"
//           data-page-length={10}
//         >
//           <thead>
//             {table.getHeaderGroups().map((headerGroup) => (
//               <tr scope="col" key={headerGroup.id}>
//                 {headerGroup.headers.map((header) => (
//                   <th
//                     style={{
//                       width: "auto",
//                       minWidth: "130px",
//                       maxWidth: "90px",
//                     }}
//                     key={header.id}
//                   >
//                     {header.isPlaceholder
//                       ? null
//                       : flexRender(
//                           header.column.columnDef.header,
//                           header.getContext()
//                         )}
//                   </th>
//                 ))}
//               </tr>
//             ))}
//           </thead>
//           <tbody>
//             {table.getRowModel().rows?.length
//               ? table.getRowModel().rows.map((row) => (
//                   <tr key={row.id}>
//                     {row.getVisibleCells().map((cell, cellIndex) => {
//                       if (cellIndex === 0) {
//                         return (
//                           <td key={cell.id}>
//                             <div className="form-check style-check d-flex align-items-center">
//                               <label
//                                 className="form-check-label"
//                                 style={{ textTransform: "capitalize" }}
//                               >
//                                 {flexRender(
//                                   cell.column.columnDef.cell,
//                                   cell.getContext()
//                                 )}
//                               </label>
//                             </div>
//                           </td>
//                         );
//                       }
//                       if (cellIndex === 1) {
//                         return (
//                           <td
//                             key={cell.id}
//                             style={{ textTransform: "capitalize" }}
//                           >
//                             <Link to="#" className="text-warning-600">
//                               {flexRender(
//                                 cell.column.columnDef.cell,
//                                 cell.getContext()
//                               )}
//                             </Link>
//                           </td>
//                         );
//                       }
//                       if (cellIndex === 2) {
//                         return (
//                           <td key={cell.id}>
//                             <div className="form-check style-check d-flex align-items-center">
//                               <label className="form-check-label">
//                                 {flexRender(
//                                   cell.column.columnDef.cell,
//                                   cell.getContext()
//                                 )}
//                               </label>
//                             </div>
//                           </td>
//                         );
//                       }

//                       if (cellIndex === 3) {
//                         return (
//                           <td key={cell.id}>
//                             <div className="d-flex align-items-center">
//                               <h6 className="text-md mb-0 fw-medium flex-grow-1">
//                                 {cell.getValue()}
//                               </h6>
//                             </div>
//                           </td>
//                         );
//                       }
//                       if (cellIndex === 4) {
//                         return <td key={cell.id}>{cell.getValue()}</td>;
//                       }
//                       if (cellIndex === 5) {
//                         return (
//                           <td key={cell.id}>
//                             {flexRender(
//                               cell.column.columnDef.cell,
//                               cell.getContext()
//                             )}
//                           </td>
//                         );
//                       }
//                       if (cellIndex === 6) {
//                         return (
//                           <td key={cell.id}>
//                             {flexRender(
//                               cell.column.columnDef.cell,
//                               cell.getContext()
//                             )}
//                           </td>
//                         );
//                       }
//                       if (cellIndex === 7) {
//                         return (
//                           <td key={cell.id}>
//                             {flexRender(
//                               cell.column.columnDef.cell,
//                               cell.getContext()
//                             )}
//                           </td>
//                         );
//                       }
//                       if (cellIndex === 8) {
//                         return (
//                           <td className="text-md mb-0 fw-medium flex-grow-1">
//                             {moment(cell.getValue()).format(
//                               "DD-MM-YYYY, HH:mm"
//                             )}
//                           </td>
//                         );
//                       }
//                       if (cellIndex === 9) {
//                         return (
//                           <td key={cell.id}>
// <Link
//   to={`/documents/translator/student/${row.original.student_id}`}
//   className="bg-primary-light text-primary-600 px-24 py-4 rounded-pill fw-medium text-sm"
// >
//   Open
// </Link>
//                           </td>
//                         );
//                       }
//                     })}
//                   </tr>
//                 ))
//               : null}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default TranslatorDocuments;

import React, { useState, useContext, useEffect } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
import { Link } from "react-router-dom";
import { GlobalInfo } from "../../App";
import "datatables.net-dt/js/dataTables.dataTables.js";
import $ from "jquery";
import moment from "moment";
import { ExternalLinkIcon } from "@radix-ui/react-icons";

const TableDataLayer = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const context = useContext(GlobalInfo);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch data from the server
  const getStudents = async () => {
    try {
      const response = await axios.get(
        `${context.apiEndPoint}admin/translator/documents`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    getStudents();
  }, [token]);

  // Initialize DataTables after data is fetched
  useEffect(() => {
    if (!loading && data.length > 0) {
      const table = $("#dataTable").DataTable({
        destroy: true, // To ensure re-initialization on data update
        pageLength: 10,
      });
      return () => {
        table.destroy(true);
      };
    }
  }, [data, loading]); // Re-initialize DataTable only after data updates

  return (
    <div className="card basic-data-table">
      <div className="card-header">
        <h5 className="card-title mb-0">Default Data Tables</h5>
      </div>
      <div className="card-body overflow-x-scroll no-scrollbar">
        <table
          className="table bordered-table mb-0"
          id="dataTable"
          data-page-length={10}
        >
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Document Name</th>
              <th>Number of Pages</th>
              <th>Net Amount</th>
              <th>Paid Amount</th>
              <th>Payment Approved Status</th>
              <th>Payment Received Status</th>
              <th>Document Translation Status</th>
              <th>Last Updated at</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading && data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td>
                    <Link
                      target="_blank"
                      to={`/documents/translator/student/${item.student_id}`}
                    >
                      <div className="text-blue-500 font-semibold flex items-center">
                        {item.first_name} {item.last_name}
                        <div className="bg-primary-light text-primary-600 rounded-full p-4">
                          <ExternalLinkIcon className="text-semibold " />
                        </div>
                      </div>
                    </Link>
                  </td>
                  <td>
                    <Link
                      className="text-blue-600"
                      to={
                        "/documents/candidate/translations/" +
                        item.student_id +
                        "/documents/" +
                        item.doc_cat_id +
                        "/" +
                        item.translation_doc_id
                      }
                    >
                      <p className="font-bold ">{item.document_name}</p>
                    </Link>
                  </td>
                  <td>
                    <div className="text-center">{item.num_pages}</div>
                  </td>
                  <td>
                    <div className="text-center">{item.net_amount}</div>
                  </td>
                  <td>
                    <div className="text-center">{item.paid_amount}</div>
                  </td>
                  <td>
                    <div className="text-center">
                      {item.is_payment_approved === 1 ? (
                        <span className="bg-success-focus text-success-main px-24 py-4 rounded-pill fw-medium text-sm">
                          Approved
                        </span>
                      ) : (
                        <span className="bg-danger-focus text-danger-main px-24 py-4 rounded-pill fw-medium text-sm">
                          Pending
                        </span>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="text-center">
                      {item.payment_status === 1 ? (
                        <span className="bg-success-focus text-success-main px-24 py-4 rounded-pill fw-medium text-sm">
                          Paid
                        </span>
                      ) : (
                        <span className="bg-danger-focus text-danger-main px-24 py-4 rounded-pill fw-medium text-sm">
                          Pending
                        </span>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="text-center">
                      {item.translation_status === 0 ? (
                        <span className="bg-warning-focus text-warning-main px-24 py-4 rounded-pill fw-medium text-sm">
                          Pending
                        </span>
                      ) : (
                        <span className="bg-success-focus text-success-main px-24 py-4 rounded-pill fw-medium text-sm">
                          Completed
                        </span>
                      )}
                    </div>
                  </td>
                  <td>{moment(item.modified_date).format("DD MMM YYYY")}</td>

                  <td>
                    <Link
                      className="bg-primary-light text-primary-600 px-24 py-4 rounded-pill fw-medium text-sm"
                      to={
                        "/documents/candidate/translations/" +
                        item.student_id +
                        "/documents/" +
                        item.doc_cat_id +
                        "/" +
                        item.translation_doc_id
                      }
                    >
                      Open
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10" className="text-center">
                  {loading ? "Loading data..." : "No data available"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableDataLayer;
