import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GlobalInfo } from "../App";
import useAuth from "../hook/useAuth";
import axios from "axios";
// import { toast } from "react-toastify";
import { Toaster, toast } from "sonner";

const SignInLayer = () => {
  const navigate = useNavigate();
  const context = useContext(GlobalInfo);

  const [usertype, setusertype] = useState("admin");
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");

  const [usernameError, setusernameError] = useState("");
  const [passwordError, setpasswordError] = useState("");

  //   const tokenData = useAuth();

  // const handleLogin = async (e) => {
  //   if (username == "") {
  //     setusernameError("Username cannot be empty");
  //   }

  //   if (password == "") {
  //     setpasswordError("Password cannot be empty");
  //   }

  //   if (username == "" || password == "") return;

  //   const obj = {
  //     email: username,
  //     password: password,
  //     usertype: usertype,
  //   };

  //   // console.log(obj);

  //   try {
  //     const response = await axios({
  //       method: "post",
  //       data: obj,
  //       url: context.apiEndPoint + `login`,
  //     });

  //     if (response.data.status) {
  //       toast.success("Login successful");

  //       try {
  //         localStorage.setItem("token", response.data.token);
  //       } catch (error) {}

  //       setTimeout(() => {
  //         if (usertype == "admin") {
  //           navigate("/");
  //         } else if (usertype == "student") {
  //           navigate("/student/dashboard");
  //         } else if (usertype == "referral") {
  //           navigate("/referral/dashboard");
  //         }
  //       }, 2000);
  //     } else {
  //       toast.error(response.data.error);
  //       console.log(response);
  //     }
  //   } catch (error) {
  //     // console.log("====================================");
  //     console.log(error);
  //     // console.log("====================================");
  //     // toast.error(error.response);
  //   }
  // };
  const handleLogin = async (e) => {
    if (username === "") {
      setusernameError("Username cannot be empty");
    }

    if (password === "") {
      setpasswordError("Password cannot be empty");
    }

    if (username === "" || password === "") return;

    const obj = {
      email: username,
      password: password,
      usertype: usertype
    };

    try {
      const response = await axios({
        method: "post",
        data: obj,
        url: context.apiEndPoint + `login`
      });

      if (response.data.status) {
        const user = response.data;

        // if (user.is_translator == 1) {
        toast.success("Login successful");

        try {
          localStorage.setItem("token", user.token);
        } catch (error) {
          console.error("Failed to save token:", error);
        }

        setTimeout(() => {
          if (usertype === "admin") {
            navigate("/documents");
          } else if (usertype === "student") {
            navigate("/student/dashboard");
          } else if (usertype === "referral") {
            navigate("/referral/dashboard");
          }
        }, 2000);
      } else {
        toast.error("Unauthorized User");
      }
      // } else {
      //   toast.error(response.data.error);
      //   console.log(response);
      // }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred during login.");
    }
  };

  return (
    <section className="auth bg-base d-flex flex-wrap ">
      <Toaster richColors position="top-right" />
      <div className="auth-left d-lg-block d-none ">
        <div className="d-flex align-items-center flex-column h-100 justify-content-center">
          <img src="https://d2c9u2e33z36pz.cloudfront.net/uploads/1731513225auth-img.png" />
        </div>
      </div>
      <div className="auth-right py-32 px-24 d-flex flex-column justify-content-center">
        {/* <div className="auth-right py-32 px-24 d-flex flex-column justify-content-center"> */}
        <div className="max-w-464-px mx-auto w-100">
          <div>
            {/* <Link to="/" className="mb-40 max-w-290-px"> */}
            <div className="flex items-start justify-start gap-4 py-4">
              <img
                src="https://d2c9u2e33z36pz.cloudfront.net/uploads/1730736392Meduniverse%20logo%20favicon.png"
                style={{ width: "70px", height: "40px" }}
              />

              {/* <h2 className="text-lg font-bold">Meduniverse</h2> */}
            </div>

            <div className="text-start py-4 my-4">
              Welcome to
              <span className="text-semibold opacity-80 text-lg px-4">
                Meduniverse
              </span>
            </div>
            {/* </Link> */}
            <h4 className="mb-12">Sign In to your Account</h4>
            <p className="mb-32 text-secondary-light text-lg">
              Please enter your details
            </p>
          </div>
          {/* <form> */}
          <div className="mb-16">
            <div className="icon-field ">
              <span className="icon top-50 translate-middle-y">
                <Icon icon="mage:email" />
              </span>
              <input
                type="email"
                className="form-control h-56-px bg-neutral-50 radius-12"
                placeholder="Email"
                onChange={(e) => {
                  setusername(e.target.value);
                  setusernameError("");
                }}
              />
            </div>
            {usernameError != "" && (
              <p className="text-red-600" style={{ color: "red" }}>
                {usernameError}
              </p>
            )}
          </div>
          <div className="position-relative mb-20">
            <div>
              <div className="icon-field">
                <span className="icon top-50 translate-middle-y">
                  <Icon icon="solar:lock-password-outline" />
                </span>
                <input
                  type="password"
                  className="form-control h-56-px bg-neutral-50 radius-12"
                  id="your-password"
                  placeholder="Password"
                  onChange={(e) => {
                    setpassword(e.target.value);
                    setpasswordError("");
                  }}
                />
              </div>
              {passwordError != "" && (
                <p className="text-red-600" style={{ color: "red" }}>
                  {passwordError}
                </p>
              )}
            </div>
            <span
              className="toggle-password ri-eye-line cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light"
              data-toggle="#your-password"
            />
          </div>
          {/* <div className="">
            <div className="d-flex justify-content-between gap-2">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input border border-neutral-300"
                  type="checkbox"
                  defaultValue=""
                  id="remeber"
                />
                <label className="form-check-label" htmlFor="remeber">
                  Remember me{" "}
                </label>
              </div>
              <Link to="#" className="text-primary-600 fw-medium">
                Forgot Password?
              </Link>
            </div>
          </div> */}
          <button
            //   type="submit"
            onClick={() => {
              handleLogin();
            }}
            className="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32"
          >
            {" "}
            Sign In
          </button>
          {/* <div className="mt-32 center-border-horizontal text-center">
            <span className="bg-base z-1 px-4">Or sign in with</span>
          </div> */}
          {/* <div className="mt-32 d-flex align-items-center gap-3">
            <button
              type="button"
              className="fw-semibold text-primary-light py-16 px-24 w-50 border radius-12 text-md d-flex align-items-center justify-content-center gap-12 line-height-1 bg-hover-primary-50"
            >
              <Icon
                icon="ic:baseline-facebook"
                className="text-primary-600 text-xl line-height-1"
              />
              Google
            </button>
            <button
              type="button"
              className="fw-semibold text-primary-light py-16 px-24 w-50 border radius-12 text-md d-flex align-items-center justify-content-center gap-12 line-height-1 bg-hover-primary-50"
            >
              <Icon
                icon="logos:google-icon"
                className="text-primary-600 text-xl line-height-1"
              />
              Google
            </button>
          </div> */}
          <div className="mt-32 text-center text-sm">
            <p className="mb-0">
              Don’t have an account?{" "}
              <Link to="/signup" className="text-primary-600 fw-semibold">
                Sign Up
              </Link>
            </p>
          </div>
          {/* </form> */}
        </div>
      </div>
    </section>
  );
};

export default SignInLayer;
