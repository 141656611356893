import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { GlobalInfo } from "../../App";

const CustomersStatisticsOne = () => {
  const context = useContext(GlobalInfo);
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [pendingDocuments, setPendingDocuments] = useState([]);
  const [statisticsDonutChartSeries, setStatisticsDonutChartSeries] = useState([
    0, 0,
  ]); // Initial series state
  const token = localStorage.getItem("token");

  const getDocuments = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${context.apiEndPoint}admin/translator/documents`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDocuments(response.data);
      const pendingDocs = response.data.filter(
        (doc) =>
          doc.translation_status === 1 &&
          doc.is_payment_approved === 1 &&
          doc.payment_status === 1
      );
      setPendingDocuments(pendingDocs);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Update statisticsDonutChartSeries whenever documents or pendingDocuments change
  useEffect(() => {
    setStatisticsDonutChartSeries([
      pendingDocuments.length,
      documents.length - pendingDocuments.length,
    ]);
  }, [documents, pendingDocuments]);

  useEffect(() => {
    getDocuments();
  }, [token]);

  const statisticsDonutChartOptions = {
    colors: ["#487FFF", "#FF9F29"],
    labels: ["Completed Documents", "Pending Documents"],
    legend: {
      show: false,
    },
    chart: {
      type: "donut",
      height: 230,
      sparkline: {
        enabled: true, // Remove whitespace
      },
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    stroke: {
      width: 0,
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div className="col-xxl-4 col-lg-6">
      <div className="card h-100 radius-8 border-0">
        <div className="card-body p-24">
          <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between">
            <h6 className="mb-2 fw-bold text-lg">
              Documents translation analysis
            </h6>
          </div>
          <div className="position-relative">
            <span className="w-80-px h-80-px bg-base shadow text-primary-light fw-semibold text-xl d-flex justify-content-center align-items-center rounded-circle position-absolute start-10 bottom-0 z-1">
              {documents.length > 0
                ? `${(
                    ((documents.length - pendingDocuments.length) /
                      documents.length) *
                    100
                  ).toFixed(0)}%`
                : "0%"}
            </span>

            <ReactApexChart
              options={statisticsDonutChartOptions}
              series={statisticsDonutChartSeries}
              type="donut"
              height={230}
              id="statisticsDonutChart"
              className="mt-36 flex-grow-1 apexcharts-tooltip-z-none title-style circle-none"
            />
            <span className="w-80-px h-80-px bg-base shadow text-primary-light fw-semibold text-xl d-flex justify-content-center align-items-center rounded-circle position-absolute end-10 top-0 z-1">
              {documents.length > 0
                ? `${(
                    (pendingDocuments.length / documents.length) *
                    100
                  ).toFixed(0)}%`
                : "0%"}
            </span>
          </div>
          <li
            className="d-flex align-items-center gap-2"
            style={{ marginTop: "30px" }}
          >
            {/* <span className="w-12-px h-12-px radius-2 bg-success-600" /> */}
            <span className="text-success-light text-sm fw-normal">
              Total Documents:
              <span className="text-primary-light fw-bold">
                {documents.length}
              </span>
            </span>
          </li>
          <ul className="d-flex flex-wrap align-items-center justify-content-between mt-3 gap-3">
            <li className="d-flex align-items-center gap-2">
              <span className="w-12-px h-12-px radius-2 bg-primary-600" />
              <span className="text-secondary-light text-sm fw-normal">
                Completed Documents:
                <span className="text-primary-light fw-bold">
                  {pendingDocuments.length}
                </span>
              </span>
            </li>
            <li className="d-flex align-items-center gap-2">
              <span className="w-12-px h-12-px radius-2 bg-yellow" />
              <span className="text-secondary-light text-sm fw-normal">
                Pending Documents:
                <span className="text-primary-light fw-bold">
                  {documents.length - pendingDocuments.length}
                </span>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CustomersStatisticsOne;
