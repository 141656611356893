import React, { useContext, useEffect, useState } from "react";
import useReactApexChart from "../../hook/useReactApexChart";
import axios from "axios";
import { GlobalInfo } from "../../App";
import ReactApexChart from "react-apexcharts";

const UnitCountFive = () => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const context = useContext(GlobalInfo);
  const [data, setData] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [netAmount, setNetAmount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const getStudents = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${context.apiEndPoint}admin/translator/students`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getDocuments = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${context.apiEndPoint}admin/translator/documents`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      setDocuments(response.data);
      console.log(typeof response.data[2].net_amount);
      setNetAmount(
        response.data.reduce(
          (netAmount, item) => netAmount + parseFloat(item.net_amount),
          0
        )
      );
      setPaidAmount(
        response.data.reduce(
          (paidAmount, item) => paidAmount + parseFloat(item.paid_amount),
          0
        )
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStudents();
    getDocuments();
  }, [token]);

  useEffect(() => {
    getStudents();
  }, []);

  let createChartSix = (color1, color2) => {
    let series = [
      {
        name: "Net Amount",
        data: documents.map((item, index) => item.net_amount),
        // data: [23, 690, 55, 154, 48, 30, 55, 50, 57],
      },
      {
        name: "Paid Amount",
        data: documents.map((item, index) => item.paid_amount),
        // data: [23, 0, 0, 26, 22, 60, 40, 48, 25],
      },
    ];
    let options = {
      legend: {
        show: false,
      },
      chart: {
        type: "area",
        width: "100%",
        height: 270,
        toolbar: {
          show: false,
        },
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
        colors: [color1, color2], // Use two colors for the lines
        lineCap: "round",
      },
      grid: {
        show: true,
        borderColor: "#D1D5DB",
        strokeDashArray: 1,
        position: "back",
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        row: {
          colors: undefined,
          opacity: 0.5,
        },
        column: {
          colors: undefined,
          opacity: 0.5,
        },
        padding: {
          top: -20,
          right: 0,
          bottom: -10,
          left: 0,
        },
      },
      fill: {
        type: "gradient",
        colors: [color1, color2],
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: [undefined, `${color2}00`], // Apply transparency to both colors
          inverseColors: false,
          opacityFrom: [0.4, 0.4], // Starting opacity for both colors
          opacityTo: [0.3, 0.3], // Ending opacity for both colors
          stops: [0, 100],
        },
      },
      markers: {
        colors: [color1, color2], // Use two colors for the markers
        strokeWidth: 3,
        size: 0,
        hover: {
          size: 10,
        },
      },
      xaxis: {
        categories: documents.map((item, index) => `Translation ${index + 1}`),
        tooltip: {
          enabled: false,
        },
        labels: {
          formatter: function (value) {
            return value;
          },
          style: {
            fontSize: "14px",
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return "€" + value;
          },
          style: {
            fontSize: "14px",
          },
        },
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    };

    return (
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={270}
      />
    );
  };

  return (
    <div className="col-xxl-8">
      <div className="card radius-8 border-0 p-20">
        <div className="row gy-4">
          <div className="col-xxl-4">
            <div className="card p-3 radius-8 shadow-none bg-gradient-dark-start-1 mb-12">
              <div className="card-body p-0">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-0">
                  <div className="d-flex align-items-center gap-2 mb-12">
                    <span className="mb-0 w-48-px h-48-px bg-base text-pink text-2xl flex-shrink-0 d-flex justify-content-center align-items-center rounded-circle h6">
                      <i className="ri-group-fill" />
                    </span>
                    <div>
                      <span className="mb-0 fw-medium text-secondary-light text-lg">
                        Total Students
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between flex-wrap gap-8">
                  <h5 className="fw-semibold mb-0">{data.length}</h5>
                  {/* <p className="text-sm mb-0 d-flex align-items-center gap-8">
                    <span className="text-white px-1 rounded-2 fw-medium bg-success-main text-sm">
                      +2.5k
                    </span>
                    This Month
                  </p> */}
                </div>
              </div>
            </div>
            <div className="card p-3 radius-8 shadow-none bg-gradient-dark-start-2 mb-12">
              <div className="card-body p-0">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-0">
                  <div className="d-flex align-items-center gap-2 mb-12">
                    <span className="mb-0 w-48-px h-48-px bg-base text-purple text-2xl flex-shrink-0 d-flex justify-content-center align-items-center rounded-circle h6">
                      <i className="ri-youtube-fill" />
                    </span>
                    <div>
                      <span className="mb-0 fw-medium text-secondary-light text-lg">
                        Total Translations
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between flex-wrap gap-8">
                  <h5 className="fw-semibold mb-0">{documents.length}</h5>
                  {/* <p className="text-sm mb-0 d-flex align-items-center gap-8">
                    <span className="text-white px-1 rounded-2 fw-medium bg-success-main text-sm">
                      +30
                    </span>
                    This Month
                  </p> */}
                </div>
              </div>
            </div>
            <div className="card p-3 radius-8 shadow-none bg-gradient-dark-start-3 mb-0">
              <div className="card-body p-0">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-0">
                  <div className="d-flex align-items-center gap-2 mb-12">
                    <span className="mb-0 w-48-px h-48-px bg-base text-info text-2xl flex-shrink-0 d-flex justify-content-center align-items-center rounded-circle h6">
                      <i className="ri-money-dollar-circle-fill" />
                    </span>
                    <div>
                      <span className="mb-0 fw-medium text-secondary-light text-lg">
                        Overall Revenue
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between flex-wrap gap-8">
                  <h5 className="fw-semibold mb-0">€{paidAmount}</h5>
                  {/* <p className="text-sm mb-0 d-flex align-items-center gap-8">
                    <span className="text-white px-1 rounded-2 fw-medium bg-success-main text-sm">
                      +1.5k
                    </span>
                    This Month
                  </p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-8">
            <div className="card-body p-0">
              <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between">
                <h6 className="mb-2 fw-bold text-lg">
                  Translation Revenue analysis
                </h6>
                {/* <div className="">
                  <select
                    className="form-select form-select-sm w-auto bg-base border text-secondary-light"
                    defaultValue="Yearly"
                  >
                    <option value="Yearly">Yearly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Today">Today</option>
                  </select>
                </div> */}
              </div>
              <ul className="d-flex flex-wrap align-items-center justify-content-center mt-3 gap-3">
                <li className="d-flex align-items-center gap-2">
                  <span className="w-12-px h-12-px rounded-circle bg-primary-600" />
                  <span className="text-secondary-light text-sm fw-semibold">
                    Net Amount:
                    <span className="text-primary-light fw-bold">
                      €{netAmount}
                    </span>
                  </span>
                </li>
                <li className="d-flex align-items-center gap-2">
                  <span className="w-12-px h-12-px rounded-circle bg-success-main" />
                  <span className="text-secondary-light text-sm fw-semibold">
                    Paid Amount:
                    <span className="text-primary-light fw-bold">
                      €{paidAmount}
                    </span>
                  </span>
                </li>
              </ul>
              <div className="mt-40">
                <div
                  id="enrollmentChart"
                  className="apexcharts-tooltip-style-1"
                >
                  {createChartSix("#45B369", "#487fff")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitCountFive;
