import React from "react";
import SignUpLayer from "../components/SignUpLayer";

const SignUpPage = () => {
  return (
    <>
      <SignUpLayer />
    </>
  );
};

export default SignUpPage;
