import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import DashBoardLayerSix from "../components/DashBoardLayerSix";
import UnitCountFive from "../components/child/UnitCountFive";
import CustomersStatisticsOne from "../components/child/CustomersStatisticsOne";

const StatisticsPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>
        <Breadcrumb title="Statistics" />
        <div className="row gy-4 mb-24">
          <UnitCountFive />
          <CustomersStatisticsOne />
        </div>
      </MasterLayout>
    </>
  );
};

export default StatisticsPage;
