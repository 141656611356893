import React, { useContext, useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import ThemeToggleButton from "../helper/ThemeToggleButton";
import { GlobalInfo } from "../App";
import axios from "axios";
import { ClientContext } from "../hook/ClientContext";

const MasterLayout = ({ children }) => {
  let [sidebarActive, seSidebarActive] = useState(false);
  let [mobileMenu, setMobileMenu] = useState(false);
  const location = useLocation(); // Hook to get the current route
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const { clientDetails } = useContext(ClientContext);

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  useEffect(() => {
    const handleDropdownClick = (event) => {
      event.preventDefault();
      const clickedLink = event.currentTarget;
      const clickedDropdown = clickedLink.closest(".dropdown");
      if (!clickedDropdown) return;
      const isActive = clickedDropdown.classList.contains("open");
      const allDropdowns = document.querySelectorAll(".sidebar-menu .dropdown");
      allDropdowns.forEach((dropdown) => {
        dropdown.classList.remove("open");
      });
      if (!isActive) {
        clickedDropdown.classList.add("open");
      }
    };

    // Attach click event listeners to all dropdown triggers
    const dropdownTriggers = document.querySelectorAll(
      ".sidebar-menu .dropdown > a, .sidebar-menu .dropdown > Link"
    );

    dropdownTriggers.forEach((trigger) => {
      trigger.addEventListener("click", handleDropdownClick);
    });

    // Function to open submenu based on current route
    const openActiveDropdown = () => {
      const allDropdowns = document.querySelectorAll(".sidebar-menu .dropdown");
      allDropdowns.forEach((dropdown) => {
        const submenuLinks = dropdown.querySelectorAll(".sidebar-submenu li a");
        submenuLinks.forEach((link) => {
          if (
            link.getAttribute("href") === location.pathname ||
            link.getAttribute("to") === location.pathname
          ) {
            dropdown.classList.add("open");
          }
        });
      });
    };

    // Open the submenu that contains the open route
    openActiveDropdown();

    // Cleanup event listeners on unmount
    return () => {
      dropdownTriggers.forEach((trigger) => {
        trigger.removeEventListener("click", handleDropdownClick);
      });
    };
  }, [location.pathname]);

  let sidebarControl = () => {
    seSidebarActive(!sidebarActive);
  };

  let mobileMenuControl = () => {
    setMobileMenu(!mobileMenu);
  };

  return (
    <section className={mobileMenu ? "overlay active" : "overlay "}>
      <aside
        className={
          sidebarActive
            ? "sidebar active "
            : mobileMenu
            ? "sidebar sidebar-open"
            : "sidebar"
        }
      >
        <button
          onClick={mobileMenuControl}
          type="button"
          className="sidebar-close-btn"
        >
          <Icon icon="radix-icons:cross-2" />
        </button>
        <div>
          <Link to="/documents" className="sidebar-logo">
            <img
              src="https://d2c9u2e33z36pz.cloudfront.net/uploads/1730736392Meduniverse%20logo%20favicon.png"
              alt="site logo"
              style={{ width: "40px", height: "25px" }}
              className="light-logo"
            />

            <img
              src="https://d2c9u2e33z36pz.cloudfront.net/uploads/1730736392Meduniverse%20logo%20favicon.png"
              alt="site logo"
              style={{ width: "40px", height: "25px" }}
              className="dark-logo"
            />
            <img
              src="https://d2c9u2e33z36pz.cloudfront.net/uploads/1730736392Meduniverse%20logo%20favicon.png"
              alt="site logo"
              style={{ width: "40px", height: "25px" }}
              className="logo-icon"
            />
            {!sidebarActive ? (
              <p className="text-lg pl-2 font-bold">
                MedUniverse
              </p>
            ) : null}
          </Link>
        </div>
        <div className="sidebar-menu-area">
          <ul className="sidebar-menu" id="sidebar-menu">
            <li className="dropdown">
              <Link to="#">
                <Icon icon="bi:translate" className="menu-icon" />
                <span>Translation</span>
              </Link>
              <ul className="sidebar-submenu">
                <li>
                  <NavLink
                    to="/documents"
                    className={(navData) =>
                      navData.isActive ? "active-page" : ""
                    }
                  >
                    Document Translation
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/student-translation"
                    className={(navData) =>
                      navData.isActive ? "active-page" : ""
                    }
                  >
                    Student Translation
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to="/statistics"
                className={(navData) => (navData.isActive ? "active-page" : "")}
              >
                <Icon icon="mdi:chart-line" className="menu-icon" />
                <span>Statistics</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </aside>

      <main
        className={sidebarActive ? "dashboard-main active" : "dashboard-main"}
        style={{
          backgroundColor: theme === "dark" ? "#1B2431" : "#F5F6FA",
        }}
      >
        <div className="navbar-header">
          <div className="row align-items-center justify-content-between">
            <div className="col-auto">
              <div className="d-flex flex-wrap align-items-center gap-4">
                <button
                  type="button"
                  className="sidebar-toggle"
                  onClick={sidebarControl}
                >
                  {sidebarActive ? (
                    <Icon
                      icon="iconoir:arrow-right"
                      className="icon text-2xl non-active"
                    />
                  ) : (
                    <Icon
                      icon="heroicons:bars-3-solid"
                      className="icon text-2xl non-active "
                    />
                  )}
                </button>
                <button
                  onClick={mobileMenuControl}
                  type="button"
                  className="sidebar-mobile-toggle"
                >
                  <Icon icon="heroicons:bars-3-solid" className="icon" />
                </button>
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex flex-wrap align-items-center gap-3">
                {/* ThemeToggleButton */}
                <ThemeToggleButton theme={theme} setTheme={setTheme} />
                <div className="dropdown">
                  <button
                    className="d-flex justify-content-center align-items-center rounded-circle"
                    type="button"
                    data-bs-toggle="dropdown"
                  >
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "var(--brand)",
                        borderRadius: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "white",
                          marginTop: "6px",
                          textTransform: "uppercase",
                        }}
                      >
                        {clientDetails && clientDetails.first_name[0]}
                        {clientDetails && clientDetails.last_name[0]}
                      </p>
                    </div>
                  </button>
                  <div className="dropdown-menu to-top dropdown-menu-sm">
                    <div className="py-12 px-16 radius-8 bg-primary-50 mb-16 d-flex align-items-center justify-content-between gap-2">
                      <div>
                        <h6 className="text-lg text-primary-light fw-semibold mb-2">
                          {clientDetails &&
                            clientDetails.first_name +
                              " " +
                              clientDetails.last_name}
                        </h6>
                        <span className="text-secondary-light fw-medium text-sm">
                          {clientDetails && clientDetails.username}
                        </span>
                      </div>
                      <button type="button" className="hover-text-danger">
                        <Icon
                          icon="radix-icons:cross-1"
                          className="icon text-xl"
                        />
                      </button>
                    </div>
                    <ul className="to-top-list">
                      <li>
                        <Link
                          onClick={() => {
                            localStorage.removeItem("token");
                            window.location.replace("/login");
                          }}
                          className="dropdown-item text-black px-0 py-8 hover-bg-transparent hover-text-danger d-flex align-items-center gap-3"
                          to="#"
                        >
                          <Icon icon="lucide:power" className="icon text-xl" />{" "}
                          Log Out
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="dashboard-main-body"
          style={{
            backgroundColor: theme === "dark" ? "#1B2431" : "#F5F6FA",
            height: "fit-content",
          }}
        >
          {children}
        </div>
        <footer className="d-footer">
          <div className="row align-items-center justify-content-between">
            <div className="col-auto">
              <p className="mb-0">© 2024 MedUniverse. All Rights Reserved.</p>
            </div>
            <div className="col-auto">
              <p className="mb-0">
                Made by <span className="text-primary-600">MedUniverse</span>
              </p>
            </div>
          </div>
        </footer>
      </main>
    </section>
  );
};

export default MasterLayout;
