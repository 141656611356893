import React, { useContext, useState } from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import TableDataLayer from "../components/TableDataLayer";
import StudentDocumentPage from "./StudentDocumentPage";
import StudentsDocuments from "../components/child/StudentDocuments";
import { useParams } from "react-router-dom";

const DocumentTranslationPage = () => {
  const [currentType, setCurrentType] = useState("documents");

  return (
    <>
      <MasterLayout>
        <Breadcrumb title="Documents" />
        <TableDataLayer
          currentType={currentType}
          setCurrentType={setCurrentType}
        />
      </MasterLayout>
    </>
  );
};

export default DocumentTranslationPage;
